import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { useTranslation } from 'react-i18next';
import Img from 'gatsby-image';

import { styled } from 'styles';

const DescriptionSection = () => {
  const { t } = useTranslation();
  const images = useStaticQuery(graphql`
    {
      descriptionImg: file(name: { eq: "about-description" }, sourceInstanceName: { eq: "sitedataAssets" }) {
        childImageSharp {
          fluid(maxWidth: 442, maxHeight: 284) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);
  return (
    <StyledDescriptionSection className="description-section">
      <div className="description-section__wrapper">
        <div className="description-section__info-block">
          <h4 className="description-section__title">{t('about_us.title2')}</h4>
          <p className="description-section__description">{t('about_us.description2')}</p>
        </div>
        <div className="description-section__photo-block">
          <div className="description-section__photo-container">
            <Img
              fluid={images.descriptionImg.childImageSharp.fluid}
              alt="description-section-photo"
              className="description-section__photo"
            />
          </div>
        </div>
      </div>
    </StyledDescriptionSection>
  );
};

const StyledDescriptionSection = styled.div`
  display: flex;
  justify-content: center;
  padding: 48px 0 0;
  .description-section {
    &__wrapper {
      flex: 1;
      display: grid;
      grid-template-columns: repeat(2, 442px);
      justify-content: space-between;
      position: relative;
      padding: 0 0 48px;
      &:after {
        content: '';
        width: 100%;
        height: 1px;
        background: ${props => props.theme.colors.lightGray};
        position: absolute;
        bottom: 0;
        left: 0;
      }
    }
    &__photo-container {
      width: 100%;
      height: 284px;
    }
    &__photo {
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center center;
    }
    &__title {
      max-width: 300px;
      font-weight: 600;
      font-size: 28px;
      line-height: 1.5;
      margin: 32px 0 36px;
    }
    &__description {
      margin: 0;
      font-weight: 500;
      font-size: 16px;
      line-height: 150%;
    }
  }
  @media (max-width: ${props => props.theme.breakpoints.md}) {
    .description-section {
      &__wrapper {
        grid-template-columns: repeat(2, 1fr);
        grid-gap: 32px;
      }
    }
  }
  @media (max-width: ${props => props.theme.breakpoints.sm}) {
    padding: 38px 0;
    .description-section {
      &__wrapper {
        grid-template-columns: 1fr;
        grid-gap: 0;
        padding: 0;
        &:after {
          display: none;
        }
      }
      &__info-block {
        margin: 0 0 57px;
      }
      &__title {
        max-width: 200px;
        font-size: 24px;
        line-height: 32px;
        margin: 0 0 36px;
      }
      &__photo-container {
        width: calc(100% + 32px);
        margin: 0 -16px 0 -16px;
      }
    }
  }
`;

export default DescriptionSection;
