import React from 'react';
import { useTranslation } from 'react-i18next';

import { styled } from 'styles';
import { Icon, ContactForm } from 'components';

const AboutContactsSection = () => {
  const { t } = useTranslation();
  return (
    <StyledAboutContactsSection className="about-contacts-section">
      <div className="about-contacts-section__wrapper">
        <div className="about-contacts__contacts-block">
          <h4 className="about-contacts-section__title">{t('about_us.title5')}</h4>
          <p className="about-contacts-section__description">{t('about_us.description5')}</p>
          <div className="about-contacts">
            <div className="about-contacts__contact">
              <Icon className="about-contacts__contact-icon" name="call" />
              <p className="about-contacts__contact-text">{t('about_us.phone')}</p>
            </div>
            <div className="about-contacts__contact">
              <Icon className="about-contacts__contact-icon" name="mail" />
              <p className="about-contacts__contact-text">{t('about_us.email')}</p>
            </div>
            <div className="about-contacts__contact">
              <Icon className="about-contacts__contact-icon" name="address" />
              <p className="about-contacts__contact-text">{t('about_us.address')}</p>
            </div>
          </div>
        </div>
        <div className="about-contacts-section__form-block">
          <StyledContactForm
            formTitle={t('about_us.title6')}
            submitButtonText={t('common.send')}
            isHiddenTelAgent
          />
        </div>
      </div>
    </StyledAboutContactsSection>
  );
};

const StyledAboutContactsSection = styled.div`
  display: flex;
  justify-content: center;
  .about-contacts-section {
    &__wrapper {
      flex: 1;
      display: grid;
      grid-template-columns: 496px 442px;
      justify-content: space-between;
      position: relative;
      padding: 0 0 48px;
      &:after {
        content: '';
        width: 100%;
        height: 1px;
        background: ${props => props.theme.colors.lightGray};
        position: absolute;
        bottom: 0;
        left: 0;
      }
    }
    &__icon {
      margin: 0 0 20px;
    }
    &__title {
      font-weight: 500;
      font-size: 20px;
      line-height: 28px;
      margin: 0 0 20px;
    }
    &__description {
      font-size: 16px;
      line-height: 24px;
      margin: 0 0 32px;
    }
  }
  .about-contacts {
    &__contact {
      display: flex;
      align-items: center;
      margin: 0 0 20px;
    }
    &__contact-icon {
      margin: 0 12px 0 0;
      background: ${props => props.theme.colors.secondary};
      fill: ${props => props.theme.colors.primary};
      border-radius: 3px;

      .call_svg,
      .address_svg,
      .mail_svg {
        &__accent {
          fill: ${props => props.theme.colors.primaryAccent};
        }
      }
    }
    &__contact-text {
      font-size: 16px;
      line-height: 1.5;
      margin: 0;
    }
  }
  @media (max-width: ${props => props.theme.breakpoints.md}) {
    .about-contacts-section {
      &__wrapper {
        grid-template-columns: repeat(2, 1fr);
        grid-gap: 32px;
      }
    }
  }
  @media (max-width: ${props => props.theme.breakpoints.sm}) {
    .about-contacts-section {
      &__wrapper {
        grid-template-columns: 1fr;
        grid-gap: 0;
        padding: 0 0 40px;
      }
      &__title {
        max-width: 285px;
      }
      &__description {
        margin: 0 0 24px;
      }
    }
    .about-contacts {
      &__contact-icon {
        margin: 0 16px 0 0;
      }
      &__contacts-block {
        padding: 0 0 28px;
      }
    }
  }
`;

const StyledContactForm = styled(ContactForm)`
  width: 100%;
  .contact-form {
    &__form {
      margin: 0;
    }
    &__title {
      margin-right: auto;
      text-align: left;
    }
    &__button {
      width: 100%;
      margin: 0;
    }
  }
  @media (max-width: ${props => props.theme.breakpoints.sm}) {
    .contact-form {
      &__textarea {
        margin: 0 0 24px;
      }
    }
  }
`;

export default AboutContactsSection;
