import React from 'react';

import { styled } from 'styles';
import Map, { Marker } from 'components/Map';

const mapPosition = {
  latitude: 41.8781,
  longitude: -87.6298
};

const AboutMap = () => {
  return (
    <StyledAboutMap className="about-map">
      <Map
        center={{
          lat: mapPosition.latitude,
          lng: mapPosition.longitude
        }}>
        <Marker lat={mapPosition.latitude} lng={mapPosition.longitude} />
      </Map>
    </StyledAboutMap>
  );
};

const StyledAboutMap = styled.div`
  margin: 0 0 63px;
  .map {
    height: 220px;
  }
  @media (max-width: ${props => props.theme.breakpoints.sm}) {
    width: calc(100% + 32px);
    margin: 0 -16px 56px -16px;
  }
`;

export default AboutMap;
