import React from 'react';
import IndexLayout from 'layouts';
import { styled } from 'styles';
import { Components } from 'sitedata';
import TopAboutSection from 'views/AboutUsPage/TopAboutSection';
import DescriptionSection from 'views/AboutUsPage/DescriptionSection';
import AboutTextSection from 'views/AboutUsPage/AboutTextSection';
import AboutContactsSection from 'views/AboutUsPage/AboutContactsSection';
import AboutMap from 'views/AboutUsPage/AboutMap';

const AboutUsPage = () => (
  <IndexLayout>
    <Components.Header />
    <TopAboutSection />
    <StyledAboutUsWrapper className="about-us-wrapper">
      <DescriptionSection />
      <AboutTextSection />
      <AboutMap />
      <AboutContactsSection />
    </StyledAboutUsWrapper>
    <Components.Footer />
  </IndexLayout>
);

const StyledAboutUsWrapper = styled.div`
  max-width: 1057px;
  margin: 0 auto;
  padding: 0 16px;
`;

export default AboutUsPage;
