import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useStaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';

import { Button, Modal, ContactForm } from 'components';
import { styled } from 'styles';

const TopAboutSection = () => {
  const { t } = useTranslation();
  const images = useStaticQuery(graphql`
    {
      cover: file(name: { eq: "cover-about" }, sourceInstanceName: { eq: "sitedataAssets" }) {
        childImageSharp {
          fluid(maxWidth: 1366, maxHeight: 600) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);

  const [openContactModal, setOpenContactModal] = useState(false);

  return (
    <StyledTopAboutSection className="about-top-section">
      <div className="about-top-section__image-wrapper">
        <Img
          fluid={images.cover.childImageSharp.fluid}
          alt="about-cover-image"
          className="about-top-section__cover-image"
        />
      </div>
      <div className="about-info-block">
        <h1 className="about-info-block__title">{t('about_us.title1')}</h1>
        <p className="about-info-block__description">{t('about_us.description1')}</p>
        <Button
          className="about-info-block__button"
          onClick={() => setOpenContactModal(true)}
          primary>
          {t('about_us.connect')}
        </Button>
      </div>
      <StyledModal
        className="contact-us-modal"
        open={openContactModal}
        onClose={() => setOpenContactModal(false)}>
        <ContactForm source="About Us Page" onSubmit={() => setOpenContactModal(false)} />
      </StyledModal>
    </StyledTopAboutSection>
  );
};

const StyledTopAboutSection = styled.div`
  height: 406px;
  padding: 16px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 16px;
  .about-top-section {
    &__image-wrapper {
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      z-index: 1;
    }
    &__cover-image {
      width: 100%;
      height: 100%;
    }
  }
  .about-info-block {
    position: relative;
    z-index: 2;
    flex: 1;
    max-width: 1025px;
    &__title {
      font-weight: 600;
      font-size: 32px;
      line-height: 1.2;
      color: ${props => props.theme.colors.white};
      margin: 0 0 16px;
    }
    &__description {
      font-weight: 500;
      font-size: 20px;
      line-height: 1.2;
      margin: 0 0 32px;
      color: ${props => props.theme.colors.white};
    }
    &__button {
      &.button {
        flex-shrink: 0;
        font-size: 12px;
        line-height: 1.2;
        width: 205px;
      }
    }
  }

  @media (max-width: ${props => props.theme.breakpoints.sm}) {
    padding: 16px 16px 47px;
    height: 621px;
    .about-info-block {
      max-width: 100%;
      &__title {
        margin: 0 0 36px;
      }
      &__description {
        margin: 0 0 36px;
      }
    }
    .about-top-section {
      &__image-wrapper {
        img {
          object-position: -400px center !important;
        }
      }
    }
  }
`;

const StyledModal = styled(Modal)`
  &.contact-us-modal {
    .contact-form {
      margin: 0 10px 12px;
    }
  }
  @media (max-width: ${props => props.theme.breakpoints.sm}) {
    &.contact-us-modal {
      .contact-form {
        margin: 0;
      }
    }
  }
`;

export default TopAboutSection;
