import React from 'react';
import { useTranslation } from 'react-i18next';

import { styled } from 'styles';
import { Icon } from 'components';

const AboutTextSection = () => {
  const { t } = useTranslation();
  return (
    <StyledAboutTextSection className="about-text-section">
      <div className="about-text-section__wrapper">
        <div className="about-text-section__text-block">
          <Icon className="about-text-section__icon" name="people-group" />
          <h4 className="about-text-section__title">{t('about_us.title3')}</h4>
          <p className="about-text-section__description">{t('about_us.description3')}</p>
        </div>
        <div className="about-text-section__text-block">
          <Icon className="about-text-section__icon" name="rocket" />
          <h4 className="about-text-section__title">{t('about_us.title4')}</h4>
          <p className="about-text-section__description">{t('about_us.description4')}</p>
        </div>
      </div>
    </StyledAboutTextSection>
  );
};

const StyledAboutTextSection = styled.div`
  display: flex;
  justify-content: center;
  padding: 40px 0 56px;
  .about-text-section {
    &__wrapper {
      flex: 1;
      display: grid;
      grid-template-columns: repeat(2, 442px);
      justify-content: space-between;
      position: relative;
    }
    &__icon {
      margin: 0 0 20px;
      fill: ${props => props.theme.colors.primary};
    }
    &__title {
      font-weight: 500;
      font-size: 16px;
      line-height: 24px;
      margin: 0 0 20px;
    }
    &__description {
      font-size: 16px;
      line-height: 24px;
      margin: 0;
    }
  }
  @media (max-width: ${props => props.theme.breakpoints.md}) {
    .about-text-section {
      &__wrapper {
        grid-template-columns: repeat(2, 1fr);
        grid-gap: 32px;
      }
    }
  }
  @media (max-width: ${props => props.theme.breakpoints.sm}) {
    padding: 0 0 16px;

    .about-text-section {
      &__wrapper {
        grid-template-columns: 1fr;
        grid-gap: 0;
      }
      &__description {
        margin: 0 0 40px;
      }
    }
  }
`;

export default AboutTextSection;
